import { CoreEntity } from '../../core-entity';

export interface UserInvitation extends CoreEntity {
  isAdmin: boolean;
  addStorageContainer: boolean;
  partners?: string[];
  organisms?: string[];
  given_name: string;
  family_name: string;
  artistID?: string;
  authenticID?: number;
  licenses: string[];
}
