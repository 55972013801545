import { AmnesicContainerColumn } from '../amnesia';
import { CoreEntity } from '../core-entity';

export interface DiffusionLicense extends CoreEntity {
  name: string;

  projectIDs: string[];
  isFullAccess: boolean;

  startedAt: Date | number;
  expiresAt: Date | number;
  expired: boolean;
  stripeID?: string;
  userID: string;

  documents: AmnesicContainerColumn;
}
