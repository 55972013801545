import { CoreEntity } from '../../core-entity';

export interface APIAccesses extends CoreEntity {
  userID: string;
  isAdmin: boolean;
  partners?: string[];
  organisms?: string[];
  storageContainer?: string;
  artistID?: string;
  authenticID?: number;
  diffusionLicenses?: string[];
  expiredLicenses?: string[];
}
